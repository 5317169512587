import { Colors } from '~/constants/colors.constants';
import { FontSizesNumber, FontWeights } from '~/types/SharedFont.types';
import type {
  ISharedKitButtonColorPreset,
  ISharedKitButtonSizePreset,
  ISharedKitButtonTypePreset,
} from '~/components/SharedKitButton/SharedKitButton.types';
import { BorderRadius } from '~/constants/common.constants';

export const PrimaryColorPreset = {
  defaultBackground: 'var(--main-color-200)',
  hoverBackground: 'var(--main-color-100)',
  loadingColor: Colors.NEUTRAL.WHITE_30,
  textColor: Colors.NEUTRAL.WHITE,
};
export const SecondaryColorPreset = {
  defaultBackground: Colors.MAIN.GOLD,
  hoverBackground: Colors.NEUTRAL.WHITE,
  loadingColor: Colors.NEUTRAL.BLACK_30,
  textColor: Colors.NEUTRAL.BLACK,
};

const sizePreset = (changeFn: ReturnType<typeof GlobalUtils.Media.changeByMedia>) => ({
  fontSize: changeFn(FontSizesNumber.LARGE, FontSizesNumber.MEDIUM),
  fontWeight: FontWeights.BOLD,
  height: changeFn(50, 40),
  type: changeFn('large', 'medium') as 'medium',
});

const colorsPresets = {
  primary: PrimaryColorPreset,
  secondary: SecondaryColorPreset,
};

interface IButtonPreset {
  colorPreset: Partial<ISharedKitButtonColorPreset>;
  sizePreset: Partial<ISharedKitButtonSizePreset>;
  typePreset: Partial<ISharedKitButtonTypePreset>;
}

export interface IButtonPresetRequired {
  colorPreset: ISharedKitButtonColorPreset;
  sizePreset: ISharedKitButtonSizePreset;
  typePreset: ISharedKitButtonTypePreset;
}

export const createCasesButtonPresetor = (
  changeFn: ReturnType<typeof GlobalUtils.Media.changeByMedia>,
  combineDefaultWith?: Partial<IButtonPreset>,
) => {
  return (preset: keyof typeof colorsPresets, extra?: Partial<IButtonPreset>): IButtonPresetRequired => {
    let presetConfig: IButtonPreset = {
      colorPreset: GlobalUtils.Objects.deepObjectCopy(colorsPresets[preset]),
      sizePreset: GlobalUtils.Objects.deepObjectCopy(sizePreset(changeFn)),
      typePreset: {
        large: BorderRadius.SS,
        medium: BorderRadius.XS,
      },
    };

    if (combineDefaultWith && GlobalUtils.Objects.isObject(combineDefaultWith)) {
      presetConfig = GlobalUtils.Objects.deepObjectsMerge(presetConfig, combineDefaultWith);
    }

    if (extra && GlobalUtils.Objects.isObject(extra)) {
      presetConfig = GlobalUtils.Objects.deepObjectsMerge(presetConfig, extra);
    }

    return presetConfig as IButtonPresetRequired;
  };
};
