<template>
  <BaseModal v-model="showModal" :class="$attrs.class">
    <div class="info-modal-wrapper" :style="cssVars">
      <slot name="image"></slot>
      <div class="content">
        <div class="title">
          <slot name="title"></slot>
        </div>
        <slot name="content"></slot>
        <slot name="action"></slot>
      </div>
    </div>
  </BaseModal>
</template>

<script lang="ts" setup>
interface IInfoModalsWrapperProps {
  width?: string;
}

const props = withDefaults(defineProps<IInfoModalsWrapperProps>(), { width: '100vw' });

const cssVars = computed(() => ({
  '--width': props.width,
}));

const showModal = defineModel<boolean>();
</script>

<style scoped lang="scss" src="./InfoModalsWrapper.scss" />
