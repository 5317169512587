<template>
  <InfoModalsWrapper v-model="model">
    <template #image>
      <UiImage class="img" src="/static/case/image-default.png" />
    </template>
    <template #title>
      <SharedTitle
        variant="band"
        :size="pageStyles.titleSize"
        :text-props="{
          fontFamily: FontFamily.REAVER,
          weight: FontWeights.BOLD,
          letterSpacing: '2.4px',
        }"
      >
        <i18n-t keypath="profile.levelsGuide.title">
          <template #br><br /></template>
        </i18n-t>
      </SharedTitle>
    </template>
    <template #content>
      <section class="text">
        <p v-for="(item, i) in $tm('profile.levelsGuide.textItems')" :key="i" class="text-item">
          <I18nT :keypath="'profile.levelsGuide.textItems.' + i" tag="div">
            <template #freeCases>
              <span class="gold"> {{ $t('profile.levelsGuide.freeCases') }}</span>
            </template>
            <template #timer>
              <span class="text-item-timer">{{ casesAvailableBy }}</span>
            </template>
          </I18nT>
        </p>
      </section>
    </template>
    <template #action>
      <SharedKitButton
        v-bind="
          presetBtn('primary', {
            colorPreset: ColorPresets.PRIMARY,
            sizePreset: changeMd(SizePresets.L, SizePresets.M),
            typePreset: TypePresets.ROUND,
          })
        "
        class="cleared-btn"
        @click="model = false"
      >
        {{ $t('profile.levelsGuide.clear') }}
      </SharedKitButton>
    </template>
  </InfoModalsWrapper>
</template>

<script setup lang="ts">
import type { IDailyCasesHowItWorksPopupProps, IPageStyles } from './HowItWorksPopup.types';
import { FontFamily, FontSizes, FontWeights } from '~/types/SharedFont.types';
import { Breakpoints } from '~/constants/media.constants';
import { createCasesButtonPresetor } from '~/features/cases/presets/buttons.preset';
import { ColorPresets, SizePresets } from '~/components/SharedKitButton/SharedKitButton.presets';
import { TypePresets } from '~/components/SharedIcon/SharedIcon.presets';

const viewport = useViewport();
const props = defineProps<IDailyCasesHowItWorksPopupProps>();

const changeXl = GlobalUtils.Media.changeByMedia('xl');
const changeMd = GlobalUtils.Media.changeByMedia('md');
const { t } = useI18n();

const pageStyles = computed<IPageStyles>(() => ({
  radius: viewport.isGreaterThan(Breakpoints.md) ? 16 : 8,
  titleSize: viewport.isGreaterThan(Breakpoints.md) ? FontSizes.LARGE_2XL : FontSizes.LARGE_XL,
}));

const presetBtn = createCasesButtonPresetor(changeXl);

const model = defineModel<boolean>('modelValue', {
  required: true,
});

const { timer } = toRefs(props);
const { timerData, start } = useTimer(timer);

onMounted(start);

const casesAvailableBy = computed(() => {
  const days = t('profile.levelsGuide.timer.days', { n: +timerData?.value.days });
  const hours = t('profile.levelsGuide.timer.hours', { n: +timerData?.value.hours });
  const minutes = t('profile.levelsGuide.timer.minutes', { n: +timerData?.value.minutes });
  return `${days} ${hours} ${minutes}`;
});
</script>

<style scoped lang="scss" src="./HowItWorksPopup.scss" />
